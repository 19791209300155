import "react-toastify/dist/ReactToastify.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import * as Sentry from "@sentry/nextjs";
import axios from "axios";
import { NextPage, NextPageContext } from "next";
import { DefaultSeo } from "next-seo";
import { AppProps } from "next/app";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { SWRConfig } from "swr";
import { setLocale } from "yup";
import { NoOrgProviders, Providers, Seo } from "~components";
import {
  apiUrl,
  domainAllowsSessionFeatureFlags,
  getDefaultSeoConfig,
} from "~config";
import {
  pixelIdAtom,
  useFacebookPixelScripts,
  useThirdpartyScripts,
} from "~features/analytics/useThirdpartyScripts";
import { SeoMetadataQuery } from "~graphql/sdk";
import { i18n, noOrgFoundRedirect, pageviewFB, protocol, State } from "~lib";
import { getImageSrc } from "~lib/helpers";
import { clientCache, ClientCacheProps } from "~lib/clientCache";
import CSSreset from "~styles/CSSreset";
import { withAuth } from "~features/auth/withAuth";
import FrontendTracer from "~telemetry/FrontendTracer";
import { useAtom } from "jotai";
import { useHydrateAtoms } from "jotai/utils";
import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import { Flex, Spinner } from "flicket-ui";
import FeatureFlagPanel from "~components/FeatureFlagPanel/FeatureFlagPanel";
import * as actions from "~features/nonseated-reservation/store/actions";
import {
  getServerSideI18n,
  getServerSideOrganization,
  getServerSideSeoMetadata,
} from "~lib/server";
import NoOrganization from "./not-found";
import { asOpenGraphImage } from "~features/competitions/competitionSeo";
import { _getSeoMetadata } from "~graphql/fetchers";

axios.defaults.baseURL = apiUrl;
axios.defaults.withCredentials = true;

setLocale({
  mixed: {
    required: "This field is required",
  },
  string: {
    email: "Invalid email address",
  },
});

toast.configure({
  position: "bottom-right",
  autoClose: 4000,
  closeButton: false,
  hideProgressBar: true,
});

const authRestrictedRoutes = [
  /^tickets$/i,
  /^orders$/i,
  /^account/i,
  /^members-area/i,
];

if (typeof window !== "undefined") {
  void FrontendTracer();
}

function useQueue(organizationFeatures: string[]) {
  const isQueueEnabled = organizationFeatures.includes("queue");
  const withoutFlash = organizationFeatures.includes("queueWithoutFlash");

  const [inQueue, setInQueue] = useState(isQueueEnabled && withoutFlash);

  useEffect(() => {
    if (typeof window === "undefined" || !isQueueEnabled) {
      return;
    }

    const client = document.createElement("script");
    client.src = "//static.queue-it.net/script/queueclient.min.js";
    document.head.appendChild(client);

    const config = document.createElement("script");
    config.src = "//static.queue-it.net/script/queueconfigloader.min.js";
    config.setAttribute("data-queueit-spa", "true");
    config.setAttribute("data-queueit-c", "flicketnz");
    document.head.appendChild(config);

    const onQueuePassed = () => setInQueue(false);

    // Capture event fired from Queue-it once user has successfully been through the queue
    window.addEventListener("queuePassed", onQueuePassed);

    let timer: NodeJS.Timeout;

    if (withoutFlash) {
      // Prevent a loading flash if the user has already been through the queue
      if (/QueueITAccepted/gi.test(document.cookie)) {
        setInQueue(false);
      }

      // In case the queue feature flag is left on and there is no queue configured in QueueIt
      timer = setTimeout(() => setInQueue(false), 1000 * 3);
    }

    return () => {
      window.removeEventListener("queuePassed", onQueuePassed);

      if (timer) {
        clearTimeout(timer);
      }
    };
  }, []);

  return inQueue;
}

export interface AppPageProps extends AppProps {
  props: ClientCacheProps & {
    url: string;
  };
}

const App = ({
  Component,
  pageProps,
  props = {} as AppPageProps["props"],
  router,
}: AppPageProps) => {
  // If we are running on the client then set the client side cache to the props values. We
  // need to do this because after the first server render, the props are serialised and sent
  // to the client. We need to initialise the client cache for the client. There after, if will
  // update with the latest prop values returned from getInitialProps.
  clientCache.organization = props.organization;
  clientCache.i18n = props.i18n;
  clientCache.seoMetadata = props.seoMetadata;

  const redirectToQueue = useQueue(props.organization?.features ?? []);

  useEffect(() => {
    actions.updateTrackingContext({
      currency: props.organization?.currency,
    });
  }, []);

  useThirdpartyScripts({
    organization: props.organization,
    gtmId: props.organization?.marketing?.gtmId,
  });

  if (!i18next.isInitialized) {
    void i18next.use(initReactI18next).init({
      lng: props.organization?.defaultI18nLanguage, // Default language
      fallbackLng: "en_default",
      debug: process.env.NODE_ENV !== "production",
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      resources: props.i18n.data,
      lowerCaseLng: true,
    });
  }

  useHydrateAtoms([[pixelIdAtom, props.organization?.marketing?.pixelId]]);

  const [pixelId] = useAtom(pixelIdAtom);

  useFacebookPixelScripts(props.organization?.marketing?.pixelId);

  useEffect(() => {
    const handleRouteChange = () => {
      pageviewFB(pixelId);
    };

    if (pixelId) {
      router.events.on("routeChangeComplete", handleRouteChange);
      return () => {
        router.events.off("routeChangeComplete", handleRouteChange);
      };
    }
  }, [router.events, pixelId]);

  useEffect(() => {
    i18n.timezone = props.organization?.timezone ?? i18n.timezone;
    i18n.locale = props.organization?.locale ?? i18n.locale;
  }, [props.organization?.timezone, props.organization?.locale]);

  useEffect(() => {
    if (props.organization?.branding?.favicon) {
      document
        .getElementById("custom-favicon")
        .setAttribute(
          "href",
          getImageSrc(props.organization?.branding?.favicon)
        );
    }
  }, [props.organization?.branding?.favicon]);

  useEffect(() => {
    if (router.pathname === "/not-found" && props.organization) {
      void router.replace("/");
    }
  }, [router, props.organization]);

  if (router.pathname === "/not-found") {
    return (
      <NoOrgProviders>
        <CSSreset />
        <NoOrganization />
      </NoOrgProviders>
    );
  }

  const authRestricted = authRestrictedRoutes.some((route) =>
    route.test(router.pathname.slice(1))
  );

  const Page = authRestricted
    ? withAuth(Component, { redirect: true })
    : Component;

  return (
    <>
      <AppSeo {...props} />

      <Providers {...props} router={router}>
        <SWRConfig
          value={{
            dedupingInterval: 5000,
            revalidateOnFocus: false,
            shouldRetryOnError: true,
            fetcher: async (url: string, params) =>
              axios(url, { params }).then((res: any): any => res?.data),
          }}
        >
          <CSSreset />

          {/* Debugging panel for feature flag toggling */}
          {domainAllowsSessionFeatureFlags && <FeatureFlagPanel />}

          {redirectToQueue ? (
            <Flex variant="center" flex="1" p={4} flexDirection="column">
              <Spinner size={48} color="P300" data-testid="spinner" />
            </Flex>
          ) : (
            <Page {...pageProps} {...props} key={router.route} />
          )}
        </SWRConfig>
      </Providers>
    </>
  );
};

/**
  DISCLAIMER:

  By adding getInitialProps to the _app component we opt out Automatic Static Optimization.

  At the time of writing there is no way of implementing a specific method (like getServerSide or getStatic props)
  however those will result in the same behavior (not having the choice page by page).

  Reason we're opting out of SSG is that this specific project will always be gated behind an organization.
  We'll need to fetch this organization first before we can display anything.

  Authentication is never a reason to go this route (just go client-side).
  In this specific case it certainly isn't as the website isn't auth-gated

  So before using this, think on how the application should work!

  @NOTE USE WITH CAUTION
**/

type ServerData = ClientCacheProps;

App.getInitialProps = async ({
  Component,
  ctx,
}: {
  Component: NextPage;
  ctx: NextPageContext;
}) => {
  const isServer = !!ctx.req;
  const host = ctx.req?.headers?.host ?? window?.location?.host;
  const currentUrl = `${protocol}://${host}${ctx.asPath}`;

  if (!host) {
    // TODO: follow up is this still necessary? what is the purose of this?
    Sentry.captureException({
      msg:
        "currentUrl with req?.headers?.referer & window?.location?.href failed",
      window,
      req: ctx.req,
      currentUrl,
    });
  }

  const returnProps: AppPageProps["props"] = {
    url: currentUrl,
    organization: undefined,
    i18n: undefined,
    seoMetadata: undefined,
  };

  if (isServer) {
    console.debug("SERVER CONTEXT");

    try {
      const serverData = await executeServerOnlyContext(ctx);

      returnProps.organization = serverData.organization;
      returnProps.i18n = serverData.i18n;
      returnProps.seoMetadata = serverData.seoMetadata;
    } catch (error) {
      console.error(error);
      // This will only fire if the organisation is not found
      if (ctx.pathname !== "/not-found") {
        return noOrgFoundRedirect(ctx.res);
      }
    }
  } else {
    console.debug("CLIENT CONTEXT");

    const clientData = await executeClientOnlyContext(ctx);

    returnProps.seoMetadata = clientData.seoMetadata;
    returnProps.organization = clientCache.organization;
    returnProps.i18n = clientCache.i18n;
  }

  Object.assign(ctx, returnProps);

  if (Component.getInitialProps) {
    const cmpProps = await Component.getInitialProps(ctx);
    Object.assign(returnProps, cmpProps);
  }

  return { props: returnProps };
};

export default App;

async function executeServerOnlyContext(
  ctx: NextPageContext
): Promise<ServerData> {
  const query = ctx.query as { [key: string]: string };

  // Wee need to get the organisation first before any other requests are made.
  const { data, error } = await getServerSideOrganization(ctx.req);

  if (!data || !data.currentOrganization || error) {
    throw error;
  }

  const i18nPromise = getServerSideI18n(data?.currentOrganization?.id);

  let seoMetadataPromise: Promise<State<SeoMetadataQuery>>;

  if (query.eventId || query.membershipId) {
    seoMetadataPromise = getServerSideSeoMetadata(
      data.currentOrganization.id,
      query.eventId ?? "",
      query.membershipId ?? ""
    );
  }

  const [i18n, maybeMetaData] = await Promise.all([
    i18nPromise,
    seoMetadataPromise,
  ]);

  if (i18n.error) {
    Sentry.captureException(i18n.error, {
      tags: {
        ssr: true,
      },
    });
  }

  if (maybeMetaData?.error) {
    Sentry.captureException(maybeMetaData?.error, {
      tags: {
        ssr: true,
      },
    });
  }

  // needed only when running Sentry in a serverless environment
  await Sentry.flush(2000);

  return {
    organization: data.currentOrganization,
    i18n: i18n?.data?.getI18n,
    seoMetadata: maybeMetaData?.data?.seoMetadata,
  };
}

async function executeClientOnlyContext(
  ctx: NextPageContext
): Promise<Partial<ClientCacheProps>> {
  const query = ctx.query as { [key: string]: string };

  let seoMetadataPromise: Promise<State<SeoMetadataQuery>>;

  if (
    (query.eventId || query.membershipId) &&
    (clientCache?.seoMetadata?.eventId !== query.eventId ||
      clientCache?.seoMetadata?.membershipId !== query.membershipId)
  ) {
    seoMetadataPromise = _getSeoMetadata(
      clientCache.organization.id,
      query.eventId ?? "",
      query.membershipId ?? ""
    );
  }

  const [maybeMetaData] = await Promise.all([seoMetadataPromise]);

  if (maybeMetaData?.error) {
    Sentry.captureException(maybeMetaData?.error);
  }

  return {
    seoMetadata: maybeMetaData?.data?.seoMetadata,
  };
}

function AppSeo(props: AppPageProps["props"]) {
  const seoMetadata = (() => {
    if (!props.seoMetadata) return;

    return {
      title: props.seoMetadata.title,
      description: props.seoMetadata.description,
      options: {
        openGraph: {
          title: props.seoMetadata.title,
          description: props.seoMetadata.description,
          images: [
            asOpenGraphImage(
              `${props.seoMetadata.openGraphImageUrl}`,
              `${props.seoMetadata.title}`
            ),
          ],
        },
      },
    };
  })();

  return (
    <>
      <DefaultSeo
        {...getDefaultSeoConfig({
          organization: props.organization,
          url: props.url,
        })}
        {...seoMetadata}
      />

      {seoMetadata && <Seo {...seoMetadata} />}
    </>
  );
}
